import classNames from 'classnames';
import {useState} from "react";
import style from "./form.module.css";
import downloadjs from 'downloadjs';
import html2canvas from 'html2canvas';
import jsPDF from "jspdf";

const Form = () => {
    /////// тут реакт хуки
    const current = new Date();
    const [name, setName] = useState('');
    const [date, setDate] = useState(`${current.getFullYear()}-${current.getMonth()+1}-${current.getDate()}`);
    const [time, setTime] = useState('09:00');
    const [styleSwitchLeft, setStyleSwitchLeft] = useState({backgroundColor: '#6E69A3', color: '#F4F4F4', transition:"0.3s"});
    const [styleSwitchRight, setStyleSwitchRight] = useState({backgroundColor: '#D9D9D9', color: '#000000', transition:"0.3s"});
    const [address, setAddress] = useState('пр-т Победы, 124');
    ////////

    // function sliceLast(e){
    //     e = e.substring(0, e.length-2) + "00"
    //     setTime(e);
    // }
    const handleCaptureClickPNG = async () => {
        const canvas = await html2canvas(document.getElementById(style.call));
        const dataURL = canvas.toDataURL('image/png');
        downloadjs(dataURL, 'download.png', 'image/png');
    };
    const switchButton = (e) => { //////// тут я меняю стили
        if (e === 1) {
            setStyleSwitchLeft({backgroundColor: '#D9D9D9', color: '#000000', transition:"0.3s"});
            setStyleSwitchRight({backgroundColor: '#6E69A3', color: '#F4F4F4', transition:"0.3s"});
            setAddress('ул. Братьев Кашириных, 108');
        }
        else{
            setStyleSwitchLeft({backgroundColor: '#6E69A3', color: '#F4F4F4', transition:"0.3s"});
            setStyleSwitchRight({backgroundColor: '#D9D9D9', color: '#000000', transition:"0.3s"});
            setAddress('пр-т Победы, 124');
        }
    }

    return( //// рендеринг готового компонента
        <div className={style.flexBlock}>
            <form className={style.form}>
                <div className={style.textInput} id={style.indent}>Имя</div>
                <input placeholder="Ведите имя..." className={style.input} type="text" value={name} onChange={(e) => {
                    setName(e.target.value)
                }}/>
                <div className={style.textInput}>Дата</div>
                <input className={style.input} value={date} type="date" onChange={(e) => {
                    setDate(e.target.value)
                }}/>
                <div className={style.textInput}>Время</div>
                <input className={style.input} value={time} type="time" onChange={(e) => {
                    setTime(e.target.value)
                }}/>
                <div className={style.textInput}>Адрес</div>
                <div className={style.tabDirectionLine}>
                    <div id={style.tabDirectionLeft} className={style.tabDirection} style={styleSwitchLeft}>
                        <input style={styleSwitchLeft} className={style.inputSwitch} type="readonly"
                               value="пр-т Победы, 124" onClick={(e) => {
                            switchButton(0)
                        }}/>
                    </div>
                    <div id={style.tabDirectionRight} className={style.tabDirection} style={styleSwitchRight}>
                        <textarea style={styleSwitchRight} className={style.inputSwitch}
                                  defaultValue="ул. Братьев Кашириных, 108" onClick={(e) => {
                            switchButton(1)
                        }}></textarea>
                    </div>
                </div>
                <div id={style.buttons} className={style.buttons}>
                    <input id={style.downlJPG} value="Скачать в PNG" className={style.downlbut}
                           onClick={handleCaptureClickPNG}/>
                </div>
            </form>
            <div id={style.call}>
                <div id={style.callText}>
                    <div className={style.name}>{name}</div>
                    <div className={style.callMassage}>приглашает вас на свой</div>

                    {/*<div id={style.day}>Day</div>*/}
                    <div id={style.birthday}>{/*<img src={"./birthday.png"}/> */}</div>

                    <div className={style.callMassage} id={style.place}>который пройдет</div>
                    <div className={style.nameDate} id={style.nameDate}>{date}</div>
                    <div className={style.start}>Начало в</div>
                    <div className={style.start} id={style.startTime}>{time}</div>
                    <div className={classNames(style.callMassage, style.address)}>по адресу</div>
                    <div className={classNames(style.callMassage, style.address)} id={style.addressId}>{address}</div>
                </div>
            </div>
            <div style={{position: "relative", top: "1400px", color: "transparent"}}> qew</div>
        </div>
    )
}

export default Form;